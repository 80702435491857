<template>
  <div>
    <b-field label="Filter logs by user, application, database, message, etc,.">
      <b-input
        v-model="filterString"
        @input="callDebounce"
      />
    </b-field>

    <b-table
      :data="logs"
      detailed
      detail-key="id"
      :show-detail-icon="true"
      class="tab-table-layout"
      paginated
      backend-pagination
      :total="total"
      per-page="20"
      @page-change="onPageChange"
    >
      <b-table-column
        v-slot="{ row }"
        field="id"
        label="ID"
      >
        {{ row.id }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="message"
        label="Message"
      >
        <strong>{{ row.name }}</strong> {{ row.message }}
      </b-table-column>

      <b-table-column
        v-slot="{ row }"
        field="modified"
        label="Modified"
      >
        {{ row.created_at }}
      </b-table-column>

      <template #detail="{ row }">
        <code-diff
          :old-string="row.old_state"
          :new-string="row.new_state"
          :context="10"
          :output-format="'side-by-side'"
        />
      </template>
    </b-table>
  </div>
</template>

<script >
import { fetchLogsService } from '@/services/audit-log-service/auditLogRequests';
import { ref, onMounted } from '@vue/composition-api';
import CodeDiff from 'vue-code-diff';
import { deepParseJson } from 'deep-parse-json';
import lodash from 'lodash';
import moment from 'moment-timezone';
import { convertUTCToLocalTime } from '@/helpers/util';
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const logs = ref([]);
  let page = ref(1);
  let total = ref(0);
  let filterString = ref('');
  const fetchLogs = async () => {
    try {
      const {
        data: {
          data
        }
      } = await fetchLogsService({
        page: page.value,
        limit: 20,
        filter: filterString.value
      });
      logs.value = data.result.map(log => ({
        name: `${log.first_name} ${log.last_name}`,
        message: log.message,
        created_at: convertUTCToLocalTime(log.created_at),
        id: log.id,
        old_state: JSON.stringify(deepParseJson(JSON.stringify(JSON.parse(log.old_state))), null, '\t'),
        new_state: JSON.stringify(deepParseJson(JSON.stringify(JSON.parse(log.new_state))), null, '\t'),
        resource: log.resource
      }));
      total.value = parseInt(data.total);
    } catch (err) {
      console.error(err);
    }
  };
  const onPageChange = newPage => {
    page.value = newPage;
    fetchLogs();
  };
  const callDebounce = lodash.debounce(() => {
    filter();
  }, 500);
  const filter = () => {
    fetchLogs();
  };
  onMounted(() => {
    fetchLogs();
  });
  return {
    logs,
    total,
    filterString,
    onPageChange,
    callDebounce
  };
};
__sfc_main.components = Object.assign({
  CodeDiff
}, __sfc_main.components);
export default __sfc_main;
</script>


<style lang="scss" >
@import '~@/style/components.scss';

.b-table {
  padding-bottom: 40px!important;
}

.d2h-wrapper {
  .d2h-files-diff {
    .d2h-file-side-diff {
      position: static !important;
    }
    .d2h-diff-tbody {
      tr {
        td {
          padding: 0!important;
        }
      }
    }
  }
} 
</style>