var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-field',{attrs:{"label":"Filter logs by user, application, database, message, etc,."}},[_c('b-input',{on:{"input":_vm.callDebounce},model:{value:(_vm.filterString),callback:function ($$v) {_vm.filterString=$$v},expression:"filterString"}})],1),_c('b-table',{staticClass:"tab-table-layout",attrs:{"data":_vm.logs,"detailed":"","detail-key":"id","show-detail-icon":true,"paginated":"","backend-pagination":"","total":_vm.total,"per-page":"20"},on:{"page-change":_vm.onPageChange},scopedSlots:_vm._u([{key:"detail",fn:function(ref){
var row = ref.row;
return [_c('code-diff',{attrs:{"old-string":row.old_state,"new-string":row.new_state,"context":10,"output-format":'side-by-side'}})]}}])},[_c('b-table-column',{attrs:{"field":"id","label":"ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"message","label":"Message"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('strong',[_vm._v(_vm._s(row.name))]),_vm._v(" "+_vm._s(row.message)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"modified","label":"Modified"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }